<i18n src="@/locales/pages/continuum-graphics-store.json"></i18n>

<template>
  <div>
    <PortfolioIntro id="intro">
      <template #title>
        {{ $t('portfolio.continuumGraphicsStore.intro.title') }}
      </template>
      <template #subtitle>
        {{ $t('portfolio.continuumGraphicsStore.intro.subtitle') }}
      </template>
      <template #description>
        {{ $t('portfolio.continuumGraphicsStore.intro.description') }}
      </template>

      <template #services>
        {{ $t('portfolio.continuumGraphicsStore.intro.details.services') }}
      </template>
      <template #tools>
        {{ $t('portfolio.continuumGraphicsStore.intro.details.tools') }}
      </template>
      <template #year>
        {{ $t('portfolio.continuumGraphicsStore.intro.details.year') }}
      </template>
      <template #preview>
        <a target="_blank" href="https://continuum.graphics/store/"
          >continuum.graphics/store</a
        >
      </template>
    </PortfolioIntro>
    <section id="request">
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.continuumGraphicsStore.request.title.short') }}
        </p>
        <h2 class="title">
          {{ $t('portfolio.continuumGraphicsStore.request.title.long') }}
        </h2>
        <div class="inner">
          <div class="text">
            <article
              v-for="(block, i) in $t(
                'portfolio.continuumGraphicsStore.request.blocks'
              )"
              :key="i"
            >
              <h3>
                {{ block.heading }}
              </h3>
              <p v-if="typeof block.text == 'string'">
                {{ block.text }}
              </p>
              <p v-else v-for="(text, j) in block.text" :key="j">
                {{ text }}
              </p>
            </article>
          </div>
          <picture class="mockup">
            <source
              srcset="@/assets/portfolio/continuum-graphics-store/introMockup.webp"
              type="image/webp"
            />
            <source
              srcset="@/assets/portfolio/continuum-graphics-store/introMockup.png"
              type="image/png"
            />
            <img
              src="@/assets/portfolio/continuum-graphics-store/introMockup.png"
              alt=""
            />
          </picture>
        </div>
      </div>
    </section>
    <section id="concept">
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.continuumGraphicsStore.concept.title.short') }}
        </p>
        <h2 class="title">
          {{ $t('portfolio.continuumGraphicsStore.concept.title.long') }}
        </h2>
        <article>
          <p
            v-for="(text, i) in $t(
              'portfolio.continuumGraphicsStore.concept.description'
            )"
            :key="i"
          >
            {{ text }}
          </p>
          <picture class="chart">
            <source
              media="(max-width: 599px)"
              :srcset="
                require(`@/assets/portfolio/continuum-graphics-store/mobileFlowChart${languageSuffix}.svg`)
              "
              type="image/svg+xml"
            />
            <source
              :srcset="
                require(`@/assets/portfolio/continuum-graphics-store/flowChart${languageSuffix}.svg`)
              "
              type="image/svg+xml"
            />
            <img
              :src="
                require(`@/assets/portfolio/continuum-graphics-store/flowChart${languageSuffix}.svg`)
              "
              alt="User Flow"
            />
          </picture>
        </article>
      </div>
    </section>
    <section id="target">
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.continuumGraphicsStore.target.title.short') }}
        </p>
        <h2 class="title">
          {{ $t('portfolio.continuumGraphicsStore.target.title.long') }}
        </h2>
        <div class="groups">
          <article
            class="group"
            v-for="(group, i) in $t(
              'portfolio.continuumGraphicsStore.target.groups'
            )"
            :key="i"
          >
            <h3>
              {{ group.heading }}
            </h3>
            <p class="description" v-html="group.description"></p>
          </article>
        </div>
      </div>
    </section>
    <section id="design">
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.continuumGraphicsStore.design.title.short') }}
        </p>
        <h2 class="title">
          {{ $t('portfolio.continuumGraphicsStore.design.title.long') }}
        </h2>
        <article class="colors">
          <div class="text">
            <h3>
              {{ $t('portfolio.continuumGraphicsStore.design.colors.heading') }}
            </h3>
            <p>
              {{
                $t('portfolio.continuumGraphicsStore.design.colors.description')
              }}
            </p>
          </div>
          <div class="swatches">
            <PortfolioColorSwatch color="#201A16" />
            <PortfolioColorSwatch color="#2560A4" />
            <PortfolioColorSwatch color="#27AE60" />
          </div>
        </article>
        <article class="typography">
          <h3>
            {{
              $t('portfolio.continuumGraphicsStore.design.typography.heading')
            }}
          </h3>
          <div class="inner">
            <p class="description">
              {{
                $t(
                  'portfolio.continuumGraphicsStore.design.typography.description'
                )
              }}
            </p>
            <div class="fonts">
              <PortfolioFont
                family="Futura PT"
                customCssName="futura-pt"
                customUrl="https://use.typekit.net/uwh2oeg.css"
              />
              <PortfolioFont family="Roboto" />
            </div>
          </div>
        </article>
      </div>
    </section>
    <section id="result">
      <a
        target="_blank"
        href="https://continuum.graphics/store"
        class="visitSite"
      >
        {{ $t('portfolio.continuumGraphicsStore.result.visitSite') }}
        <i class="icon-open-in-new"></i>
      </a>
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.continuumGraphicsStore.result.title.short') }}
        </p>
        <h2 class="title">
          {{ $t('portfolio.continuumGraphicsStore.result.title.long') }}
        </h2>
        <div class="gallery">
          <picture class="scMobileHome">
            <source
              media="(max-width: 599px)"
              srcset="@/assets/portfolio/continuum-graphics-store/mobileMockup.webp"
              type="image/webp"
            />
            <source
              media="(max-width: 599px)"
              srcset="@/assets/portfolio/continuum-graphics-store/mobileMockup.png"
              type="image/png"
            />
            <source
              srcset="@/assets/portfolio/continuum-graphics-store/sc_mobile_home.webp"
              type="image/webp"
            />
            <source
              srcset="@/assets/portfolio/continuum-graphics-store/sc_mobile_home.jpg"
              type="image/jpg"
            />
            <img
              src="@/assets/portfolio/continuum-graphics-store/sc_mobile_home.jpg"
              alt="Screenshot"
            />
          </picture>
          <picture class="scDesktopHome">
            <source
              srcset="@/assets/portfolio/continuum-graphics-store/sc_desktop_home.webp"
              type="image/webp"
            />
            <source
              srcset="@/assets/portfolio/continuum-graphics-store/sc_desktop_home.jpg"
              type="image/jpg"
            />
            <img
              src="@/assets/portfolio/continuum-graphics-store/sc_desktop_home.jpg"
              alt="Screenshot"
            />
          </picture>
          <div class="checkoutSection">
            <p class="heading">
              {{ $t('portfolio.continuumGraphicsStore.result.checkout') }}
            </p>
            <div class="inner">
              <picture class="scCheckoutCart">
                <source
                  srcset="@/assets/portfolio/continuum-graphics-store/sc_checkout_cart.webp"
                  type="image/webp"
                />
                <source
                  srcset="@/assets/portfolio/continuum-graphics-store/sc_checkout_cart.jpg"
                  type="image/jpg"
                />
                <img
                  src="@/assets/portfolio/continuum-graphics-store/sc_checkout_cart.jpg"
                  alt="Screenshot"
                />
              </picture>
              <picture class="scCheckoutPayment">
                <source
                  srcset="@/assets/portfolio/continuum-graphics-store/sc_checkout_payment.webp"
                  type="image/webp"
                />
                <source
                  srcset="@/assets/portfolio/continuum-graphics-store/sc_checkout_payment.jpg"
                  type="image/jpg"
                />
                <img
                  src="@/assets/portfolio/continuum-graphics-store/sc_checkout_payment.jpg"
                  alt="Screenshot"
                />
              </picture>
              <picture class="scCheckoutDelivery">
                <source
                  srcset="@/assets/portfolio/continuum-graphics-store/sc_checkout_delivery.webp"
                  type="image/webp"
                />
                <source
                  srcset="@/assets/portfolio/continuum-graphics-store/sc_checkout_delivery.jpg"
                  type="image/jpg"
                />
                <img
                  src="@/assets/portfolio/continuum-graphics-store/sc_checkout_delivery.jpg"
                  alt="Screenshot"
                />
              </picture>
            </div>
          </div>
          <div class="profileSection">
            <p class="heading">
              {{ $t('portfolio.continuumGraphicsStore.result.checkout') }}
            </p>
            <div class="inner">
              <picture class="scProfileOrders">
                <source
                  srcset="@/assets/portfolio/continuum-graphics-store/sc_profile_orders.webp"
                  type="image/webp"
                />
                <source
                  srcset="@/assets/portfolio/continuum-graphics-store/sc_profile_orders.jpg"
                  type="image/jpg"
                />
                <img
                  src="@/assets/portfolio/continuum-graphics-store/sc_profile_orders.jpg"
                  alt="Screenshot"
                />
              </picture>
              <picture class="scProfileSubscriptions">
                <source
                  srcset="@/assets/portfolio/continuum-graphics-store/sc_profile_subscriptions.webp"
                  type="image/webp"
                />
                <source
                  srcset="@/assets/portfolio/continuum-graphics-store/sc_profile_subscriptions.jpg"
                  type="image/jpg"
                />
                <img
                  src="@/assets/portfolio/continuum-graphics-store/sc_profile_subscriptions.jpg"
                  alt="Screenshot"
                />
              </picture>
              <picture class="scProfileDownloads">
                <source
                  srcset="@/assets/portfolio/continuum-graphics-store/sc_profile_downloads.webp"
                  type="image/webp"
                />
                <source
                  srcset="@/assets/portfolio/continuum-graphics-store/sc_profile_downloads.jpg"
                  type="image/jpg"
                />
                <img
                  src="@/assets/portfolio/continuum-graphics-store/sc_profile_downloads.jpg"
                  alt="Screenshot"
                />
              </picture>
              <picture class="scProfileSettings">
                <source
                  srcset="@/assets/portfolio/continuum-graphics-store/sc_profile_settings.webp"
                  type="image/webp"
                />
                <source
                  srcset="@/assets/portfolio/continuum-graphics-store/sc_profile_settings.jpg"
                  type="image/jpg"
                />
                <img
                  src="@/assets/portfolio/continuum-graphics-store/sc_profile_settings.jpg"
                  alt="Screenshot"
                />
              </picture>
              <img
                src="@/assets/portfolio/continuum-graphics-store/sc_profile_popup.svg"
                alt="Screenshot"
                class="scProfilePopup"
              />
            </div>
          </div>
          <div class="loginSection">
            <p class="heading">
              {{ $t('portfolio.continuumGraphicsStore.result.login') }}
            </p>
            <div class="inner">
              <img
                src="@/assets/portfolio/continuum-graphics-store/sc_login_card.svg"
                alt="Screenshot"
              />
              <img
                src="@/assets/portfolio/continuum-graphics-store/sc_login_register.svg"
                alt="Screenshot"
              />
              <img
                src="@/assets/portfolio/continuum-graphics-store/sc_login_verify.svg"
                alt="Screenshot"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PortfolioIntro from './layout/PortfolioIntro';
import PortfolioColorSwatch from './layout/PortfolioColorSwatch';
import PortfolioFont from './layout/PortfolioFont';

import Rellax from 'rellax';

export default {
  name: 'ContinuumGraphicsStore',
  components: {
    PortfolioIntro,
    PortfolioColorSwatch,
    PortfolioFont,
  },
  metaInfo() {
    return {
      title: 'Continuum Graphics Shop ◃ Portfolio ◂ Dennis Adamczyk',
      meta: [
        {
          name: 'description',
          content:
            'Continuum Graphics Shop – Portfolio – Dennis Adamczyk • Web Developer & UI/UX Designer – Shop for Minecraft shaders and texture packs',
        },
        {
          name: 'keywords',
          content:
            'dennis adamczyk, continuum graphics, shop, store, portfolio, projekt',
        },
      ],
    };
  },
  mounted() {
    new Rellax('.rellax', {
      breakpoints: [600, 768, 1201],
    });
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    languageSuffix() {
      return this.$i18n.locale !== this.$i18n.fallbackLocale
        ? `-${this.$i18n.locale}`
        : '';
    },
  },
  methods: {
    handleScroll() {
      let visitSiteBtn = document.querySelector('#result .visitSite');
      let resultSection = document.getElementById('result');
      let footer = document.querySelector('#app > footer');

      if (
        window.scrollY >
        document.documentElement.scrollHeight - resultSection.offsetHeight
      ) {
        if (!visitSiteBtn.classList.contains('show'))
          visitSiteBtn.classList.add('show');

        let updateBottom = () => {
          if (
            window.scrollY >=
            document.documentElement.scrollHeight -
              window.innerHeight -
              footer.offsetHeight
          ) {
            let newBottom =
              window.scrollY +
              window.innerHeight -
              (document.documentElement.scrollHeight - footer.offsetHeight);
            visitSiteBtn.style.setProperty(
              'transition',
              'background 120ms ease, bottom 0ms ease, opacity 120ms ease'
            );
            visitSiteBtn.style.setProperty(
              'bottom',
              (newBottom || 0) + 24 + 'px'
            );
          } else {
            visitSiteBtn.removeAttribute('style');
          }
        };
        updateBottom();
        setTimeout(updateBottom, 20);
      } else {
        if (visitSiteBtn.classList.contains('show'))
          visitSiteBtn.classList.remove('show');
      }
    },
  },
};
</script>

<style lang="scss" src="./layout/projects.scss" scoped></style>
<style lang="scss" scoped>
#intro {
  position: relative;
  z-index: 1;
}

#request {
  background-color: $background-light;

  .content {
    .background-text {
      left: 35%;

      @media all and ($mobile) {
        left: -14px;
      }
    }

    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 1;

      @media all and ($mobile) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
      }

      .text {
        min-width: 50%;
        width: 50%;

        @media all and ($mobile) {
          min-width: 100%;
          width: 100%;
        }
      }

      .mockup {
        margin-left: -2%;
        width: 80%;
        min-width: 80%;

        @media all and ($mobile) {
          margin-left: -10%;
          width: 120%;
          min-width: 120%;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

#concept {
  .content {
    .chart {
      width: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

#target {
  background-color: $background-light;

  .content {
    .background-text {
      left: 7%;

      @media all and ($mobile) {
        left: -14px;
      }
    }

    .groups {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media all and ($mobile) {
        display: block;
      }

      .group {
        width: calc(50% - 24px);

        &:last-child {
          margin-bottom: 0;
        }

        @media all and ($desktop) {
          margin-bottom: 0;
        }

        @media all and ($mobile) {
          width: 100%;
        }

        .description {
          & ::v-deep span {
            color: $primary;
          }
        }
      }
    }
  }
}

#design {
  .content {
    .colors {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media all and ($mobile) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
      }

      .text {
        margin-right: 24px;

        @media all and ($mobile) {
          width: 100%;
          min-width: 100%;
          margin-right: 0;
        }
      }

      .swatches {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas:
          'first second'
          'first third';
        gap: 24px;
        width: 65%;
        min-width: 65%;

        @media all and ($mobile) {
          width: 100%;
          min-width: 100%;
        }

        &::before {
          content: '';
          width: 0;
          padding-bottom: calc(50% - 12px);
          grid-row: 1 / 1;
          grid-column: 1 / 1;
        }

        & > * {
          &:nth-child(1) {
            grid-area: first;
          }
          &:nth-child(2) {
            grid-area: second;
          }
          &:nth-child(3) {
            grid-area: third;
          }
        }
      }
    }

    .typography {
      .inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media all and ($mobile) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }

        .description {
          padding-top: 16px;
          min-width: 50%;
          max-width: 50%;
          white-space: pre-line;

          @media all and ($mobile) {
            padding-top: 0;
            padding-bottom: 24px;
            min-width: 100%;
            max-width: 100%;
          }
        }

        .fonts {
          margin-top: -32px;
          margin-left: 80px;

          @media all and ($mobile) {
            margin-top: 0;
            margin-left: 0;
          }

          & > * {
            margin-bottom: 24px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

#result {
  background-color: $background-light;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    background-color: $background;
    top: 32%;
    height: 68%;
    z-index: 0;

    @media all and ($mobile) {
      top: 60%;
      height: 40%;
    }
  }

  .content {
    z-index: 1;

    .background-text {
      left: 25%;

      @media all and ($mobile) {
        left: -14px;
      }
    }

    .gallery {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-auto-rows: auto;
      grid-template-areas:
        'scMobileHome scDesktopHome'
        'scMobileHome checkoutSection'
        'profileSection profileSection'
        'loginSection loginSection';
      gap: 32px;
      align-items: start;
      width: 100%;
      max-width: 100%;
      margin-top: 32px;

      @media all and ($mobile) {
        grid-template-columns: 100%;
        grid-template-areas:
          'scMobileHome'
          'scDesktopHome'
          'checkoutSection'
          'profileSection'
          'loginSection';
        gap: 16px;
      }

      picture {
        display: flex;
        justify-content: stretch;
        align-items: center;
        width: 100%;
        border-radius: 20px;
        box-shadow: map-get($elevation, 3);
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }

      .heading {
        font-size: 2rem;
        font-weight: 700;
        color: $text-color;
        text-align: left;
        margin-top: 32px;
        margin-bottom: 16px;

        @media all and ($mobile) {
          font-size: 1.5rem;
        }
      }

      .scMobileHome {
        grid-area: scMobileHome;
        border-radius: 12px;

        @media all and ($mobile) {
          border-radius: 0;
          box-shadow: none;
          width: 150%;
          margin-left: -25%;
          margin-top: -25%;
        }
      }
      .scDesktopHome {
        grid-area: scDesktopHome;
      }

      .checkoutSection {
        grid-area: checkoutSection;

        .inner {
          display: grid;
          grid-template-columns: 100%;
          grid-auto-rows: auto;
          gap: 32px;
        }
      }

      .profileSection {
        grid-area: profileSection;

        .inner {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-auto-rows: auto;
          grid-template-areas:
            'scProfileOrders scProfileOrders .'
            '. scProfileSubscriptions scProfileSubscriptions'
            'scProfileDownloads scProfileDownloads .'
            '. scProfileSettings scProfileSettings';
          gap: 0;
          align-items: start;
          position: relative;

          @media all and ($mobile) {
            grid-template-columns: 1fr 6fr 1fr;
          }

          & > * {
            margin-top: -48px;

            @media all and ($mobile) {
              margin-top: -16px;
            }

            &:first-child {
              margin-top: 0;
            }

            &:nth-child(odd) {
              z-index: 1;
            }
          }

          .scProfileOrders {
            grid-area: scProfileOrders;
          }
          .scProfileSubscriptions {
            grid-area: scProfileSubscriptions;
          }
          .scProfileDownloads {
            grid-area: scProfileDownloads;
          }
          .scProfileSettings {
            grid-area: scProfileSettings;
            margin-top: 16px;

            @media all and ($mobile) {
              margin-top: 32px;
            }
          }
          .scProfilePopup {
            position: absolute;
            top: 72%;
            left: 60%;
            transform: translate(-50%, -50%);
            width: 59%;

            @media all and ($mobile) {
              width: 100%;
            }
          }
        }
      }
      .loginSection {
        grid-area: loginSection;

        .heading {
          margin-bottom: -16px;

          @media all and ($mobile) {
            margin-bottom: 16px;
          }
        }

        .inner {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;

          img {
            width: 50%;
            height: auto;

            &:last-child {
              position: relative;
              margin-top: -160px;
              left: 15%;
              z-index: 1;

              @media all and ($mobile) {
                position: initial;
                margin-top: 0;
              }
            }

            @media all and ($mobile) {
              width: 100%;
              margin-top: -48px;

              &:nth-child(even) {
                margin-left: -5%;
                z-index: 1;
              }

              &:nth-child(odd) {
                margin-left: 5%;
                z-index: 0;
              }

              &:first-child {
                margin-top: 0;
              }

              &:last-child {
                margin-top: -48px;
                margin-left: 9%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
